import React from "react";
import { CarouselItem } from "../../interfaces/Pages";
import Card from "./Card/Card";

interface CardsProps {
  cards: Array<CarouselItem>;
  hasText: boolean;
  hasDesc: boolean;
  hasBody: boolean;
}

const CarouselCards = ({
  cards,
  hasText = true,
  hasDesc = false,
  hasBody = false,
}: CardsProps) => {
  return (
    <div className={"cards-wrapper" + (cards.length < 3 ? " reduced-card-wrapper" : "")}>
      {cards &&
        cards.map((card, i) => (
          <div className="card-wrapper" key={i}>
            <Card
              img={card.field_homepage_carousel_item_img.url}
              imgWebp={card.field_homepage_carousel_item_img?.url}
              imgSmall={card.field_hp_carousel_item_img_small?.url}
              imgSmallWebp={card.field_hp_carousel_item_img_small?.url}
              title={hasText ? card.title : ""}
              key={i}
              description={hasText ? card.body : ""}
              url={card.field_hp_carousel_item_link?.uri}
              linkLabel={card.field_hp_carousel_item_link?.title}
              target={""}
              hasText={hasText}
              imgTitle={card.field_homepage_carousel_item_img.title}
            />
            {hasDesc && <h4>{card.field_homepage_carousel_item_img?.title}</h4>}
            {hasBody && <div dangerouslySetInnerHTML={{ __html: card.body }} />}
          </div>
        ))}
    </div>
  );
};

export default CarouselCards;
