import * as yup from "yup";

export const title = yup.string();
export const firstName = yup
  .string()
  .required("yup.firstname.required")
  .min(2, "yup.firstname.min")
  .max(50, "yup.firstname.max");
export const lastName = yup
  .string()
  .required("yup.lastname.required")
  .min(2, "yup.lastname.min")
  .max(50, "yup.lastname.max");
export const country = yup.string().required("yup.country.required");
export const healthcare_organization = yup
  .string()
  .required("yup.healthcareOrganization.required");
export const email = yup
  .string()
  .required("yup.email.required")
  .matches(/([a-z0-9_\-.]+)@([a-z0-9_\-.]+)\.([a-z]{2,5})/, {
    message: "yup.email.notValid",
  });
export const message = yup.string().required("yup.message.required");
export const password = yup
  .string()
  .required("yup.password.required")
  .matches(/^.*(?=\S{10,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])[^"'\s]*$/, {
    message: "yup.password.notValid",
  });
export const confirm_password = yup
  .string()
  .required("yup.passwordConfirm.required")
  .oneOf([yup.ref("password"), null], "yup.passwordConfirm.notValid");
export const registration_number = yup
  .string()
  .min(3, "yup.registrationNumber.min")
  .max(32, "yup.registrationNumber.max")
  .required("yup.registrationNumber.required");
export const address = yup.string().required("yup.address.required");
export const phoneNumber = yup
  .string()
  .required("yup.phoneNumber.required")
  .matches(/^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
    message: "yup.phoneNumber.notValid",
  });
export const postcode = yup.string().required("yup.postalCode.required");
export const name = yup.string().required("yup.name.required");
export const condition = yup.string().required("yup.condition.required");
export const access_code = yup
  .string()
  .required("yup.accessCode.required")
  .min(6, "yup.accessCode.min")
  .max(32, "yup.accessCode.max");
export const optIn = yup.boolean();
export const privacyPolicy = yup
  .boolean()
  .required("yup.privacyPolicy.required")
  .isTrue("yup.privacyPolicy.notValid");
export const pieces = yup.string().required("yup.orderKit.unit");
export const kit_type = yup.string().required("yup.orderKit.kitType");

export const homePage = "home";
export const patient = "patient";
export const idacio = "idacio";
export const tyenne = "tyenne";
export const HCP = "hcp";
export const patientRestricted = "patient-restricted";
export const hcpRestricted = "hcp-restricted";
export const roleTypes = [patient, HCP, patientRestricted, hcpRestricted, ""];

//form actions
export const ATTEMPT_LOGIN = "ATTEMPT_LOGIN";
export const ATTEMPT_REGISTER_PATIENT = "ATTEMPT_REGISTER_PATIENT";
export const ATTEMPT_REGISTER_HCP = "ATTEMPT_REGISTER_HCP";
export const ATTEMPT_GET_ACCESS_CODE_TYPE = "ATTEMPT_GET_ACCESS_CODE_TYPE"
export const ATTEMPT_UPDATE_PROFILE = "ATTEMPT_UPDATE_PROFILE";
export const ATTEMPT_GET_USER_DATA = "ATTEMPT_GET_USER_DATA";
export const ATTEMPT_ORDER_COOLBAG = "ATTEMPT_ORDER_COOLBAG";
export const ATTEMPT_REQUEST_A_REP_CALL = "ATTEMPT_REQUEST_A_REP_CALL";
export const ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL =
  "ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL";
export const ATTEMPT_CHANGE_PASSWORD = "ATTEMPT_CHANGE_PASSWORD";

export const ATTEMPT_CREATE_TOOLBOX_ITEM = "ATTEMPT_CREATE_TOOLBOX_ITEM";
export const ATTEMPT_DELETE_TOOLBOX_ITEM = "ATTEMPT_DELETE_TOOLBOX_ITEM";
export const ATTEMPT_EDIT_TOOLBOX_ITEM = "ATTEMPT_EDIT_TOOLBOX_ITEM";

export const ATTEMPT_LOG_MEDICATION = "ATTEMPT_LOG_MEDICATION";
export const ATTEMPT_SET_REMINDER = "ATTEMPT_SET_REMINDER";
export const ATTEMPT_CREATE_FLARE = "ATTEMPT_CREATE_FLARE";
export const ATTEMPT_ORDER_KIT = "ATTEMPT_CREATE_ORDER_KIT";
export const ATTEMPT_GET_FLARE = "ATTEMPT_GET_FLARE";

export const ATTEMPT_GET_KIT_TYPE = "ATTEMPT_GET_KIT_TYPE";

export const RESTRICTED_PAGES_HCP = "/hcp-access";
export const RESTRICTED_PAGES_PATIENT = "/my-idacio";
