import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../api/Api";
import "../../assets/scss/ContactUs/ContactUs.scss";
import "../../assets/scss/CoolBagForm/Form.scss";
import "../../assets/scss/Register/HCP/HCP.scss";
import Dialog from "../../component/Dialog";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_REQUEST_A_REP_CALL } from "../../constants/constants";
import useDisclosure from "../../hooks/useDisclosure";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { loadingState } from "../../store/Reducer";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import { createGAEvent, getLoadingData, wrapFormData } from "../../util/util";
import { getContactFormStructure } from "./ContactFormStructure";

export default function ContactForm(props) {
  const [questionTypes, setQuestionTypes] = useState([]);
  const [titles, setTitles] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formStructure = getContactFormStructure(questionTypes, titles, t);
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_REQUEST_A_REP_CALL
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    Api.getTitles().then((resp) => {
      setTitles([{id: "", label: ""}, ...resp.data.content]);
    });

    Api.getQuestionTypes().then((resp) => {
      setQuestionTypes(resp.data.content);
    });
  }, []);

  const onSubmit = (data) => {
    if(data.field_name_title === ""){
      delete data["field_name_title"]
    }

    data = wrapFormData(data, ["field_question_type", "field_name_title"]);

    data.type = "hcp_contact_form";

    dispatch(loading(ATTEMPT_REQUEST_A_REP_CALL));

    Api.postForm(data)
      .then((data) => {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.REP_CALL_REQUESTED);
        onOpen();
        dispatch(finishedLoading(ATTEMPT_REQUEST_A_REP_CALL, "", true));
      })
      .catch((error) => {
        dispatch(finishedLoading(ATTEMPT_REQUEST_A_REP_CALL, "", false));
      });
  };

  return (
    <>
      <Container component="main" className={"contact-us-page"}>
        <Box className="contact-us-form">
          <Box className={"contact-us-form-header"}>
            <Typography component="h1" variant="h1" className="main-title">
              {t("contactFormStructure.title")}
            </Typography>
          </Box>
          <Box className="contact-us-form-items">
            <DynamicForm
              formStructure={formStructure}
              onSubmit={onSubmit}
              classes={"contact-us-form-items-inner"}
              loading={isLoading}
              success={isSuccess}
            />
            <Dialog
              open={isOpen}
              button1Text={t("contactFormStructure.dialog.close")}
              onButton1={() => {
                onClose();
              }}
              contentText={t("contactFormStructure.dialog.message")}
              title={t("contactFormStructure.dialog.title")}
              className="form"
            />
          </Box>
        </Box>
      </Container>
      <Footer legal_number={"DIG-BIO-2024-01"} />
    </>
  );
}
