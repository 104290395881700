import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { RestrictedPagePopup } from "../component/Popup/RestrictedPagePopup";
import { Restriction } from "../interfaces/Restriction";
import { authState } from "../store/Reducer";

interface PrivateRouteProps {
  path: string;
  exact?: boolean;
  children: React.ReactChildren | React.ReactChild;
  restriction?: Restriction
}

function PrivateRoute({restriction, children, ...rest}: PrivateRouteProps) {
  const auth = useSelector(authState);

  const hasPermission = (restriction && auth?.roles.includes(restriction)) || (!restriction && auth.authenticated)

  return <Route {...rest}>{hasPermission ? children : <RestrictedPagePopup />}</Route>;
}

export default PrivateRoute;
