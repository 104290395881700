import { Button } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "../../assets/scss/Nav/Nav.scss";
import { NavMenu } from "../../interfaces/NavMenu";
import logoPng from "../../resources/logo/png/kabicare.png";
import logo from "../../resources/logo/webp/kabicare.webp";
import { InverseButton } from "../InverseButton";
import accountIcon from "../../resources/images/nav/profile.png";
import CollapsibleMenu from "./CollapsibleMenu";
import MenuItem from "./MenuItem";
import { authState } from "../../store/Reducer";
import {
  HCP,
  hcpRestricted, idacio,
  patient,
  patientRestricted, tyenne,
} from "../../constants/constants";
import useSuppressBodyScroll from "../../hooks/useSuppressBodyScroll";
import {useTranslation} from "react-i18next";

interface MobileMenuProps {
  menu: NavMenu;
  isOpen: boolean;
  onClose: () => void;
  authenticated: boolean | undefined;
  onLogin: () => void;
  onRegister: () => void;
  onLogout: () => void;
  logoutLabel: string;
}

export default function MobileMenu({
  menu,
  onClose,
  authenticated,
  onLogin,
  onLogout,
  onRegister,
}: MobileMenuProps) {
  useSuppressBodyScroll();
  const { pathname } = useLocation();
  const [currPath, setCurrPath] = React.useState(pathname);

  const auth = useSelector(authState);
  const userRoles = auth.roles;

  const { t } = useTranslation();

  const checkRoles = (roles) => {
    if (roles?.content || roles.length===0) {
      return true;
    } else if (roles.includes(patient) && userRoles?.includes(patient)) {
      return true;
    } else if (roles.includes(HCP) && userRoles?.includes(HCP)) {
      return true;
    } else if (roles.includes(idacio) && userRoles?.includes(idacio)) {
      return true;
    } else if (roles.includes(tyenne) && userRoles?.includes(tyenne)) {
      return true;
    } else if (
        roles.includes(hcpRestricted) ||
        roles.includes(patientRestricted)
    ) {
      return true;
    }

    return false;
  };

  const filterAccessibleLinkByRole = (menu) => {
    if (!menu) return [];
    return Object.values(menu).filter((item: any) => {
      if ((item?.roles as any)?.content) {
        item.roles = [""];
      }
      return checkRoles(item.roles);
    });
  };

  React.useEffect(() => {
    if (pathname === currPath) return;

    setCurrPath(pathname);
    onClose();
  }, [currPath, onClose, pathname]);

  return (
    <div className="menu slide-left">
      <div>
        <div className="header">
          <div className="side-container">
            <div className="left-side">
              <RouterLink to="/">
                <picture>
                  <source
                    media="(min-width:1280px)"
                    srcSet={logo}
                    type="image/webp"
                  />
                  <source
                    media="(min-width:1280px)"
                    srcSet={logoPng}
                    type="image/jpeg"
                  />
                  <source srcSet={logo} type="image/webp" />
                  <img src={logoPng} alt="logo" width="178px" height="45px" />
                </picture>
              </RouterLink>
            </div>
          </div>
        </div>

        <div className="menu-links">
          {Object.values(menu)?.map((menuItem) => {
            const filteredChild = filterAccessibleLinkByRole(menuItem.child);
            const hasChildren = filteredChild.length > 0;

            if (!checkRoles(menuItem.roles)) {
              return;
            }

            return hasChildren ? (
              <CollapsibleMenu menuItem={menuItem} level={1} />
            ) : (
              <MenuItem
                url={menuItem.url}
                roles={menuItem.roles}
                label={menuItem.label}
              />
            );
          })}
        </div>

        {!auth.authenticated &&
            <div className="menu-links my-treatment">
              <MenuItem
                url={"/my-treatment"}
                roles={[""]}
                label={t("nav.myTreatment")}
              />
            </div>
        }
      </div>

      <div className="auth-button">
        {authenticated ? (
          <>
            <RouterLink to="/your-account">
              <Button className="account-button" aria-label="account">
                <img src={accountIcon} alt="account" height="20" width="15" />
              </Button>
            </RouterLink>
            <InverseButton onClick={onLogout} aria-label="Logout">
              {t("nav.button.logout.label")}
            </InverseButton>
          </>
        ) : (
          <>
            <InverseButton onClick={onLogin} aria-label="Login">
              {t("nav.button.login.label")}
            </InverseButton>
            <Button onClick={onRegister} aria-label="Register">
              {t("nav.button.register.label")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
