/* eslint-disable @typescript-eslint/no-array-constructor */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../assets/scss/Home/Home.scss";
import DynamicFooter from "../../component/Footer/DynamicFooter";
import { ContentPageContainer } from "../../component/PageContainer/ContentPageContainer";
import { HomePageType } from "../../interfaces/HomePageType";
import { CarouselItem, PageType } from "../../interfaces/Pages";
import {authState, contentState} from "../../store/Reducer";
import { LowerHomePageSection } from "./LowerHomePageSection";
import {idacio} from "../../constants/constants";

export const HomePage = () => {
  const content: HomePageType = useSelector(contentState)[PageType.home];

  const auth = useSelector(authState);
  const userRoles = auth.roles;

  const [heroContent, setHeroContent] = useState<CarouselItem>();

  const [inactiveCard, setInactiveCard] = useState<CarouselItem>();

  const [carouselCards, setCarouselCards] = useState(new Array());

  useEffect(() => {
    // @ts-ignore
    if (!content) {
      return;
    }

    if (!inactiveCard) {
      const carousel = [...content.sections[0].carousel_items];

      const inActive = carousel.splice(0, 1)[0];

      setCarouselCards(carousel);

      setHeroContent(inActive);

      setInactiveCard(inActive);
    }
  }, [content]);

  useEffect(() => {
    if (!content) {
      return;
    }

    let carousel = [...content.sections[0].carousel_items];
    if (!userRoles?.includes(idacio)) {
      carousel = [...carousel.filter(c => c.nid !== 18)];
    }

    const inActive = carousel.splice(0, 1)[0];
    setCarouselCards(carousel);
    setHeroContent(inActive);
    setInactiveCard(inActive);
  }, [content, auth]);

  const uriFixed = heroContent?.field_hp_carousel_item_link?.uri?.replaceAll(
    "internal:/",
    ""
  );

  return (
    <ContentPageContainer className={"home"}>
      <section className="upper-section">
        <div className="hero">
          <div className={"hero-image-container"}>
            <picture className="hero-img">
              <source
                media="(min-width:768px)"
                srcSet={heroContent?.field_hero_image.url}
                type="image/webp"
              />
              <source
                media={"(min-width: 400px)"}
                srcSet={
                  heroContent?.field_hero_image_medium?.url ||
                  heroContent?.field_hero_image.url
                }
                type={"image/png"}
              />
              <img
                src={
                  heroContent?.field_hero_image_small?.url ||
                  heroContent?.field_hero_image.url
                }
                alt={heroContent?.field_hero_image.alt}
                height="100%"
                width="100%"
              />
            </picture>
          </div>
          <div className="hero-content">
            <div className="hero-content-container">
              <h1 className={"site-title"}>{heroContent?.title}</h1>
              <p
                dangerouslySetInnerHTML={{ __html: heroContent?.body || "" }}
              ></p>
              {heroContent?.field_hp_carousel_item_link &&
                heroContent.field_hp_carousel_item_link.title && (
                  <p className="cta-link-wrapper">
                    <RouterLink to={uriFixed}>
                      {heroContent.field_hp_carousel_item_link.title}
                    </RouterLink>
                  </p>
                )}
            </div>
          </div>
          {inactiveCard && (
            <LowerHomePageSection
              setHeroContent={setHeroContent}
              carouselCards={carouselCards}
              setCarouselCards={setCarouselCards}
              inactiveCard={inactiveCard}
              setInactiveCard={setInactiveCard}
            />
          )}
          <div className="left-side-linear-blur"></div>
        </div>
      </section>
      <DynamicFooter {...content?.footer_dynamic} />
    </ContentPageContainer>
  );
};
