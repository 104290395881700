import i18n from "i18next";
import en from "./en/ns.json";
import cr from "./cr/ns.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: en,
  },
  cr: {
    translation: cr,
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "cr",
  fallbackLng: "cr",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
