import { email } from "../../constants/constants";
import { FormComponent } from "../../interfaces/FormComponent";
import { FormStructure } from "../../interfaces/FormStructure";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const getLoginFormStructure = (): FormStructure => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: t("login.fields.email.label"),
        name: "name",
        type: "text",
        autoComplete: "email",
        autoFocus: true,
        placeholder: " ",
      },
      {
        component: FormComponent.TextField,
        label: t("login.fields.password.label"),
        name: "pass",
        type: "password",
        autoComplete: "current-password",
        placeholder: " ",
      },
      // {
      //   component: FormComponent.CheckBox,
      //   label: t("login.fields.rememberMe"),
      //   name: "field_remember_me",
      //   type: "checkbox",
      //   autoComplete: "off",
      // },
    ],
    submitLabel: t("login.fields.submit.label"),
    schema: yup.object().shape({
      name: email,
      password: yup.string(),
    }),
  };
};
