/* eslint-disable no-useless-computed-key */
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { LoginPage } from "../../interfaces/Pages";

import RegisterAsPatientImage from "../../resources/images/auth/register_patient_image.png";
import RegisterAsHCPImage from "../../resources/images/auth/register_hcp_image.png";
import { useTranslation } from "react-i18next";
import "../../i18n/config";

export default function RegisterBoard(props: LoginPage) {
  const { userType } = useParams();
  const { t } = useTranslation();

  let boardContent = "";

  if (userType === "hcp") {
    boardContent = t("register.form.common.content.hcpContent");
  } else if (userType === "patient") {
    boardContent = t("register.form.common.content.patientContent");
  } else {
    boardContent = t("register.form.common.content.commonContent");
  }

  return (
    <div className={"register-box"}>
      <div className="register-left-side">
        <Typography variant="h1">
          <Box mr={{ lg: 33, sm: 5 }} pb={1}>
            {t("register.form.common.pageTitle")}
          </Box>
        </Typography>
        <div className="register-card-content">
          <Typography variant="h6" component="div">
            <Box>
              <div
                dangerouslySetInnerHTML={{
                  __html: boardContent,
                }}
              ></div>
            </Box>
          </Typography>
        </div>
        <div className={"register-card-note"}>
          <Box mt={8} className={"foot-note"}>
            {t("register.form.common.sectionDescription")}
          </Box>
        </div>
      </div>
      <div className={"button-outer-container"}>
        <div className="register-buttons-container">
          {userType !== "hcp" && (
            <Grid item xs={12} sm={6} className={"button-grid"}>
              <Link
                to="/patient/register"
                variant="body2"
                underline={"none"}
                className={"patient-register-button register-button"}
              >
                <div
                  className={"button-content-container"}
                  aria-label={t("register.form.common.patientTile.label")}
                >
                  <img
                    src={RegisterAsPatientImage}
                    alt=""
                    className="button-image"
                  />
                  <div className="button-label">
                    {t("register.form.common.patientTile.label")}
                  </div>
                </div>
              </Link>
            </Grid>
          )}
          {/*{userType !== "patient" && (*/}
          {/*  <Grid item xs={12} sm={6} className={"button-grid"}>*/}
          {/*    <Link*/}
          {/*      to="/hcp/register"*/}
          {/*      variant="body2"*/}
          {/*      underline={"none"}*/}
          {/*      className={"hcp-register-button register-button"}*/}
          {/*    >*/}
          {/*      <div*/}
          {/*        className={"button-content-container"}*/}
          {/*        aria-label={t("register.form.common.hcpTile.label")}*/}
          {/*      >*/}
          {/*        <img*/}
          {/*          src={RegisterAsHCPImage}*/}
          {/*          alt=""*/}
          {/*          className="button-image"*/}
          {/*        />*/}
          {/*        <div className="button-label">*/}
          {/*          {t("register.form.common.hcpTile.label")}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
}
