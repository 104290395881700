import React from "react";
import * as yup from "yup";
import {
  access_code,
  condition,
  confirm_password,
  country,
  email,
  firstName,
  healthcare_organization,
  lastName, optIn,
  password,
  privacyPolicy,
  registration_number,
  title,
} from "../../constants/constants";
import {FormComponent} from "../../interfaces/FormComponent";
import {FormStructure, Option} from "../../interfaces/FormStructure";
import {RegisterHCPPage} from "../../interfaces/Pages";
import {changeLink} from "../../util/util";

const countryOptions: Array<Option> = [
  { id: "England", label: "England" },
  { id: "Wales", label: "Wales" },
  { id: "Scotland", label: "Scotland" },
  { id: "Northern Ireland", label: "Northern Ireland" },
];

export const getRegisterHCPFormStructure = (
  hcpPage: RegisterHCPPage,
  t
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.Select,
        label: t("register.form.hcp.title"),
        name: "title",
        type: "text",
        options: Object.values(
          hcpPage.register_hcp_page_select_field_titles
        ).map((title) => ({ id: title.value, label: title.label })),
        autoComplete: "honorific-prefix",
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.email"),
        name: "email",
        type: "text",
        autoComplete: "email",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.firstName"),
        name: "firstName",
        type: "text",
        autoComplete: "given-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.password"),
        name: "password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.lastName"),
        name: "lastName",
        type: "text",
        autoComplete: "family-name",
        placeholder: " ",
        required: true,
      },

      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.confirmPassword"),
        name: "confirm_password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.Select,
        label: t("register.form.hcp.country"),
        name: "country",
        type: "text",
        options: countryOptions,
        autoComplete: "country",
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.registrationNumber"),
        name: "registration_number",
        type: "text",
        autoComplete: "off",
        placeholder: " ",
        pattern: "^[a-zA-Z0-9-_.]{1,}$",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.healthcareOrganization"),
        name: "healthcare_organization",
        type: "text",
        autoComplete: "organization",
        placeholder: " ",
        pattern: "^[a-zA-Z0-9-_.]{1,}$",
        required: true,
      },
      {
        component: FormComponent.CheckBox,
        label: (
          <div
            dangerouslySetInnerHTML={{
              __html: changeLink(t("register.form.hcp.readPolicy")),
            }}
          ></div>
        ),
        name: "field_user_email_sending",
        defaultChecked: false,
        type: "checkbox",
        autoComplete: "off",
      },
    ],
    schema: yup.object().shape({
      title: title,
      firstName: firstName,
      lastName: lastName,
      country: country,
      healthcare_organization: healthcare_organization,
      email: email,
      password: password,
      confirm_password: confirm_password,
      registration_number: registration_number,
      field_user_email_sending: privacyPolicy,
    }),
    //title: t('register.form.hcp.pageTitle'),
    submitLabel: t("register.form.hcp.submitLabel"),
  };
};

export const RegisterPatientForm: {email: string, access_code: string, condition: string | undefined, password: string, confirm_password: string, field_user_email_sending: number}= {
  email: "",
  access_code: "",
  condition: "",
  password: "",
  confirm_password: "",
  field_user_email_sending: 0
};

export const RegisterPatientFormValidationScheme = yup.object().shape({
  email: email,
  condition: condition,
  access_code: access_code,
  password: password,
  confirm_password: confirm_password,
  field_user_email_sending: optIn,
});
