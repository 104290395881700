import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Api } from "../../api/Api";
import { updateContent } from "../../store/PageContentActions";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import {
  getReferencedData,
  getReferencedMedia,
  getReferencedSectionData,
  unwrapObjectResponse,
  unwrapResponse,
  useQuery,
} from "../../util/util";

interface ContentPageContainerProps {
  children: any;
  className?: string;
}

export const ContentPageContainer = ({
  children,
  className,
}: ContentPageContainerProps) => {
  const location = useLocation().pathname.replace("/", "");
  const dispatch = useDispatch();
  let isMobile = useQuery().get("mobile") === "true";

  useEffect(() => {
    dispatch(loading(location));

    if (location === "your-condition") {
      let uriString = location;
      Api.getPage(uriString.replaceAll("-", "_") + "_page")
        .then((resp) => {
          const newContent = resp[0];

          if (!newContent) {
            return;
          }

          dispatch(updateContent(location, newContent));

          if (newContent.carousel_items) {
            Api.getNode(newContent.carousel_items.replaceAll(" ", "")).then(
              (carResp) => {
                const unwrapped = unwrapResponse(carResp);

                dispatch(
                  updateContent(location, {
                    carousel_items: unwrapped,
                  })
                );

                dispatch(finishedLoading(location, resp.msg, true));
              }
            );
          }

          const refs = Object.keys(newContent)
            .filter((key) => {
              return key.includes("section_column_ref");
            })
            .map((key) => newContent[key])
            .join(",");

          if (refs.length !== 0) {
            Api.getNode(refs).then((columnResp) => {
              const newColumnState = {};

              unwrapResponse(columnResp).forEach((r, i) => {
                newColumnState["section_column_ref_" + (i + 1)] = r;
              });

              dispatch(
                updateContent(location, {
                  ...newColumnState,
                })
              );

              dispatch(finishedLoading(location, resp.msg, true));
            });
          }

          if (newContent.symptom_gallery) {
            Api.getNode(newContent.symptom_gallery.replaceAll(" ", "")).then(
              (galleryResp) => {
                const unwrapped = unwrapResponse(galleryResp);

                dispatch(
                  updateContent(location, {
                    symptom_gallery: unwrapped,
                  })
                );

                dispatch(finishedLoading(location, resp.msg, true));
              }
            );
          }

          if (
            !newContent.carousel_items &&
            !newContent.symptom_gallery &&
            refs.length !== 0
          ) {
            dispatch(finishedLoading(location, resp.msg, true));
          }
        })
        .catch((error) => {
          finishedLoading(location, error.msg, false);
        });
    } else {
      Api.getNodeByTitle(
        location
          .split("/")
          [location.split("/").length - 1].replaceAll("-", "_")
          .replaceAll("'", "%27") + "_page",
        isMobile,
        //this is the machine name in the backend for Dynamic page type
        "chrons_disease_page"
      )
        .then((resp) => {
          const data: any = unwrapObjectResponse(resp[0]);
          let orderedData: any = {};

          Object.keys(data)
            .sort((s1, s2) => {
              const first = s1.match(/(\d+)/);
              const second = s2.match(/(\d+)/);

              if (first && second) {
                return Number(first[0]) - Number(second[0]);
              }

              return 0;
            })
            .forEach((key) => {
              if (key.includes("section_")) {
                const parts = key.split(/(section_\d*)/);

                if (!orderedData[parts[1]]) {
                  orderedData[parts[1]] = {};
                }

                orderedData[parts[1]][parts[2].replace("_", "")] = data[key];
              }
            });

          orderedData = Object.values(orderedData);

          getReferencedSectionData(
            resp[0],
            orderedData,
            "gallery_items",
            dispatch,
            location
          );
          getReferencedSectionData(
            resp[0],
            orderedData,
            "carousel_items",
            dispatch,
            location
          );
          getReferencedSectionData(
            resp[0],
            orderedData,
            "column_items",
            dispatch,
            location
          );
          getReferencedData(
            resp[0],
            "field_footer_dynamic",
            dispatch,
            location,
            "footer_dynamic"
          );
          getReferencedMedia(
            resp[0],
            "field_attachments1",
            dispatch,
            location,
            "attachments1"
          );
          getReferencedMedia(
            resp[0],
            "field_attachments2",
            dispatch,
            location,
            "attachments2"
          );

          dispatch(
            updateContent(location, {
              ...data,
              sections: Object.values(orderedData),
            })
          );

          dispatch(finishedLoading(location, resp.msg, true));

          if (
            location === "my-idacio" ||
            location === "my-idacio/my-progress" ||
            location === "my-tyenne" ||
            location === "my-tyenne/my-progress"
          ) {
            dispatch(finishedLoading("my-progress", resp.msg, true));
            dispatch(
              finishedLoading(
                "my-progress/symptom-tracker",
                resp.msg,
                true
              )
            );
            dispatch(
              finishedLoading(
                "my-progress/symptom-tracker/settings",
                resp.msg,
                true
              )
            );
          }
        })
        .catch((error) => {
          dispatch(finishedLoading(location, error.msg, false));
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const uriArray = location.split("/");
  const pageName = uriArray[uriArray.length - 1];

  return (
    <div
      className={`${className || "dynamic-page dynamic-" + pageName + "-page"}`}
    >
      {children}
    </div>
  );
};
