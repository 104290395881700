import {Box, FormControlLabel, FormHelperText, MenuItem} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {default as React, useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
import "../../assets/scss/CoolBagForm/Form.scss";
import Footer from "../../component/Footer/Footer";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import {contentState, toolBoxState} from "../../store/Reducer";
import mapDataToEntityFormat, {changeLink, createGAEvent} from "../../util/util";
import {Condition} from "../../interfaces/Condition";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonWithLoader from "../../component/ButtonWithLoader";
import {Field, Form, Formik} from "formik";
import debounce from "lodash.debounce";
import {getContent} from "../../store/PageContentActions";
import {PageType} from "../../interfaces/Pages";
import {ToolBoxData} from "../../interfaces/ToolBoxData";
import {RegisterPatientForm, RegisterPatientFormValidationScheme} from "./RegisterFormStructure";

export default function RegisterFormPatient(props) {
  const content = useSelector(contentState);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const { formErrors, msg }: ToolBoxData = useSelector(toolBoxState);

  const [roleBasedConditions, setRoleBasedConditions] = useState<Condition[]>([]);
  const [disableDiagnosedConditionInput, setDisableDiagnosedConditionInput] = useState(true);

  const formData = RegisterPatientForm;
  const formValidationSchema = RegisterPatientFormValidationScheme;

  const [apiError, setApiError] = useState({
    email: "",
    access_code: "",
    other: false
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getContent(PageType.registerPatient));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (data: any) => {
    setIsLoading(true);
    setApiError({email: "", access_code: "", other: false});

    // map data
    data.name = data.email;
    const formData = mapDataToEntityFormat(data);

    Api.registerPatient(formData).then((response) => {
      createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.PATIENT_REGISTERED);
      history.push("/patient/thank-you");
    }, () => {
      setApiError({...apiError, other: true});
    }).finally(() => setIsLoading(false));
  };

  const getError = (fieldName: string) => {
    if (formErrors && formErrors[fieldName]) {
      return formErrors[fieldName]
          .map((field: any) => field.error_title)
          .join(" , ");
    }

    return "";
  };

  const handleAccessCodeChange = (value: string) => {
    if (value.length >= 6 && value.length <= 32) {
      Api.getAccessCodeType(value).then((response: any) => {
        if (response.success) {
          if(response.data.types === null) {
            setRoleBasedConditions([]);
            setApiError({...apiError, access_code: t("yup.accessCode.invalid")});
          } else {
            // map response
            const keys: string[] = [];
            const conditions: Condition[] = [];
            Object.keys(response.data.conditions).forEach(condition => {
              keys.push(condition);
            });
            keys.forEach(key => conditions.push(response.data.conditions[key]));

            setRoleBasedConditions(conditions);
            setApiError({...apiError, access_code: ""});
            setDisableDiagnosedConditionInput(false);
          }
        } else {
          setRoleBasedConditions([]);
          setApiError({...apiError, access_code: t("yup.accessCode.invalid")});
        }
      }).catch((error) => {
        setApiError({...apiError, access_code: t("yup.accessCode.invalid")});
        setRoleBasedConditions([]);
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }

  const debounceHandler = useCallback(debounce(handleAccessCodeChange, 3000), []);

  return (
    <>
      <Container
        component="main"
        className={"register-page patient-register-page"}>
        <Box className="register-form register-patient-form">
          <div className="register-form-inner-container">
            <Box>
              <Typography variant="h1" className="main-title">
                {t("register.form.patient.pageTitle")}
              </Typography>
            </Box>
            <div className="register-form-items">

              <Formik initialValues={formData}
                      validationSchema={formValidationSchema}
                      onSubmit={values => submit(values)}>
                    {({errors, touched, handleChange, resetForm, values}) => (
                    <Form>

                      {/*email*/}
                      <div>
                        <div className="input-container">
                          <Field placeholder=" " name="email" className="form-input full-width"/>
                          <label className="animated-label">
                            {t("register.form.patient.email")}
                          </label>
                        </div>

                        {errors.email && touched.email ? (
                            <FormHelperText error>{t(errors.email)}</FormHelperText>
                        ) : null}

                        <FormHelperText error>{t(getError("email"))}</FormHelperText>
                      </div>

                      {/*access code*/}
                      <div>
                        <div className="input-container">
                          <Field onInput={(event: any) => {
                            setIsLoading(true);
                            setRoleBasedConditions([]);
                            setDisableDiagnosedConditionInput(true);
                            debounceHandler(event.target.value);
                            resetForm({values:{...values, condition: ""}});
                          }}
                          placeholder=" " name="access_code" className="form-input full-width"/>
                          <label className="animated-label">
                            {t("register.form.patient.accessCode")}
                          </label>
                        </div>

                        {errors.access_code && touched.access_code ? (
                            <FormHelperText error>{t(errors.access_code)}</FormHelperText>
                        ) : null}

                        {apiError.access_code.length > 0 && (
                            <FormHelperText error>{apiError.access_code}</FormHelperText>
                        )}

                        <FormHelperText error>{t(getError("access_code"))}</FormHelperText>
                      </div>

                      {/*condition*/}
                      <div>
                        <TextField select
                                   className="select-container select-grid"
                                   variant="outlined"
                                   margin="normal"
                                   label={t("register.form.patient.diagnosedCondition")}
                                   name="condition"
                                   disabled={disableDiagnosedConditionInput}
                                   onChange={handleChange}
                                   SelectProps={{
                                     IconComponent: () => (
                                         <ExpandMoreIcon className="Select__Icon"/>
                                     ),
                                   }}>
                          {roleBasedConditions.map((val: Condition) => {
                            return (
                                <MenuItem value={val.id} key={val.id}>
                                  {val.label}
                                </MenuItem>
                            );
                          })}
                        </TextField>

                        {errors.condition && touched.condition ? (
                            <FormHelperText error>{t(errors.condition)}</FormHelperText>
                        ) : null}
                      </div>

                      {/*password*/}
                      <div>
                        <div className="input-container">
                          <Field type="password" placeholder=" " name="password" className="form-input full-width"/>
                          <label className="animated-label">
                            {t("register.form.patient.password")}
                          </label>
                        </div>

                        {errors.password && touched.password ? (
                            <FormHelperText error>{t(errors.password)}</FormHelperText>
                        ) : null}
                      </div>

                      {/*confirm password*/}
                      <div>
                        <div className="input-container">
                          <Field type="password" placeholder=" " name="confirm_password" className="form-input full-width"/>
                          <label className="animated-label">
                            {t("register.form.patient.confirmPassword")}
                          </label>
                        </div>

                        {errors.confirm_password && touched.confirm_password ? (
                            <FormHelperText error>{t(errors.confirm_password)}</FormHelperText>
                        ) : null}
                      </div>

                      {/*email notification*/}
                      <div id="email_notification_confirmation">
                        <div className="checkbox-grid">
                          <FormControlLabel
                              style={{margin: 0}}
                              name="field_user_email_sending"
                              onChange={handleChange}
                              control={
                                <label className="container checkbox-label">
                                  <input type="checkbox"/>
                                  <span className="checkmark"></span>
                                </label>
                              }
                              label={<div
                                  dangerouslySetInnerHTML={{
                                    __html: changeLink(t("register.form.patient.readPolicy")),
                                  }}></div>}/>
                        </div>
                      </div>

                      {/*general api errors*/}
                      {apiError.other && (
                          <FormHelperText style={{display: "flex", justifyContent: "center"}} error>{msg}</FormHelperText>
                      )}

                      {/*submit button*/}
                      <div className="button-container">
                        <ButtonWithLoader
                            type="submit"
                            color="primary"
                            loading={isLoading}
                            success={false}
                            aria-label={t("register.form.patient.submitLabel")}
                            className="submit-button">
                          {t("register.form.patient.submitLabel")}
                        </ButtonWithLoader>
                      </div>

                    </Form>
                )}
              </Formik>

            </div>
          </div>
        </Box>
      </Container>
      <Footer legal_number={content?.["register-patient"]?.legal_number}/>
    </>
  );
}
